import './App.css';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Table } from 'antd';
import React from "react";
import * as XLSX from 'xlsx';
import XMLParser from 'react-xml-parser';
import moment from 'moment';
import axios from 'axios';
const { Dragger } = Upload;






const App = () => {
  const [data, setData] = React.useState(null);
  const [response, setResponse] = React.useState([]);
  const [dataSource, setDataSource] = React.useState([]);
  const columns = [
    {
      title: 'Sr.no.',
      dataIndex: 'key',
      key: 'key',
    },
      {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'APW (Days)',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'VW JETTA',
      dataIndex: 'car2',
      key: 'car2',
    },
    {
      title: 'FIAT 500X',
      dataIndex: 'car3',
      key: 'car3',
    },
    {
      title: 'NISSAN PATHFINDER',
      dataIndex: 'car4',
      key: 'car4',
    },
    {
      title: 'MAZDA 3 4 DOOR',
      dataIndex: 'car5',
      key: 'car5',
    },
    {
      title: 'NISSAN ROGUE',
      dataIndex: 'car6',
      key: 'car6',
    },
    {
      title: 'TOYOTA RAV4',
      dataIndex: 'car7',
      key: 'car7',
    },
    {
      title: 'CHEVROLET EQUINOX',
      dataIndex: 'car8',
      key: 'car8',
    },
    {
      title: 'NISSAN KICKS',
      dataIndex: 'car9',
      key: 'car9',
    },
    {
      title: 'CHEVROLET SPARK',
      dataIndex: 'car10',
      key: 'car10',
    }
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      const filterJson = sheetData?.map(o=> {
        return {
          source: o["OAG Code"],
          destination: o["OAG Code"],
          code: o["Destination Market"],
          duration: o["APW ( in days) "],
          from: moment().add(Number(o["APW ( in days) "]), "days").format("YYYY-MM-DDT12:00:00"),
          to: moment().add(Number(o["APW ( in days) "]) + 1, "days").format("YYYY-MM-DDT12:00:00")
        }
      })
      setData(filterJson);
      filterJson.map(o=>onApiCall(o))

    };

    reader.readAsBinaryString(file);
  };

  const onApiCall = (data) => {
    const requestOptions = {
      method: "POST",
      url: "https://easycontent-dev.ibism.com/api/v1/hertz/xml",
      data: {
        "pickUpCode": data.source,
        "dropUpCode": data.destination,
        "age": "1992-05-04",
        "pickUpDate": data.from,
        "dropUpDate": data.to,
        "countryCode": data.code
      },
      headers: {
        'Content-Type': 'application/json',
      }
    };
    axios(requestOptions)
    .then((result) => {
      let src = {
        key: dataSource.length + 1,
        location: data.source,
        duration: data.duration,
        // car1: 'No',
        car2: 'No',
        car3: 'No',
        car4: 'No',
        car5: 'No',
        car6: 'No',
        car7: 'No',
        car8: 'No',
        car9: 'No',
        car10: 'No',
      }
      result?.data?.data?.map((obj) => {
        if(obj.name.toUpperCase().includes("VW JETTA")){
          src.car2 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("FIAT 500X")){
          src.car3 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("NISSAN PATHFINDE")){
          src.car4 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("MAZDA 3 4 DOOR")){
          src.car5 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("NISSAN ROGUE")){
          src.car6 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("TOYOTA RAV4")){
          src.car7 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("CHEVROLET EQUINOX")){
          src.car8 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("NISSAN KICKS")){
          src.car9 = 'Yes'
        }
        if(obj.name.toUpperCase().includes("CHEVROLET SPARK")){
          src.car10 = 'Yes'
        }
      })

      setDataSource(oldArray => [...oldArray, {...src, key: oldArray.length + 1}]);
      console.log("DataSource", dataSource)
      setResponse(result?.data?.data);
    })
    // .catch((error) => alert("Failed to get data"));
  }

  return (
      <div className="App">
        {/*<Dragger onChange={handleFileUpload} style={{margin: '20px',width: '450px', height: '250px'}}>*/}
        {/*  <p className="ant-upload-drag-icon">*/}
        {/*    <InboxOutlined />*/}
        {/*  </p>*/}
        {/*  <p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
        {/*  <p className="ant-upload-hint">*/}
        {/*    Support for a single or bulk upload. Strictly prohibited from uploading company data or other*/}
        {/*    banned files.*/}
        {/*  </p>*/}
        {/*</Dragger>*/}
        <div>
          <input type="file" onChange={handleFileUpload}/>
        {/*  <h2>Imported Data:</h2>*/}
        {/*  {response && response?.map(o =>*/}
        {/*      <div>*/}
        {/*        <pre>{o.name}</pre>*/}
        {/*      </div>*/}
        {/*  )}*/}
        </div>
        <Table pagination={false} dataSource={dataSource} columns={columns}/>

      </div>
  )
};

export default App;


